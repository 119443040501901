@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type='checkbox'] {
  cursor: pointer;
  position: relative;
}

input[type='checkbox']:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 1px solid #99afc1;
  border-radius: 3px;
  padding: 1px;
  background-color: #ffffff;
}

input[type='checkbox']:checked:before {
  background-color: #00aeef;
}

input[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

#root {
  height: 100vh;
  width: 100%;
}

.layout {
  display: flex;
}

.content {
  flex-grow: 1;
  padding: 20px;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landing-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  position: relative;
}

.left-svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.right-svg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 50px;
}

.branding {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-links {
  display: flex;
  gap: 30px;
  align-items: center;
}

.landing-page-title {
  font-size: 24px;
}

.landing-page-options {
  color: #66442c !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px !important; /* 150% */
  letter-spacing: -0.16px;
  text-decoration: none !important;
}

.landing-page-paragraph {
  color: #79310e !important;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Figtree !important;
  font-size: 48px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 60px !important; /* 125% */
}

.landing-page-description {
  color: #222 !important;
  text-align: justify !important;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Figtree !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 32px !important; /* 200% */
  align-self: stretch;
}

.sidebar-navbar {
  background-color: #fffaeb;
  display: flex;
  height: 100%;
  width: 250px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  z-index: 99999;
}

.sidebar-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}
.sidebar-logo-part {
  display: flex;
  height: 50px !important;
  padding: 8px 16px !important;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0;
  align-self: stretch;
}
.sidebar-logo-heading {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 25px !important; /* 125% */
}

.personal-workspace-region {
  border-right: 3px solid #febc2e !important;
  background: #fff !important;
  display: flex !important;
  height: 46px !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  gap: 12px !important;
  align-self: stretch;
}

.personal-workspace-inner-div {
  display: flex;
  width: 191px !important;
  height: 46px !important;
  align-items: center !important;
  gap: 10px !important;
}

.personal-workspace-name {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal !important;
}

.personal-workspace-create-section {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  gap: 18px !important;
  align-self: stretch !important;
}

.create-workspace-section {
  display: flex;
  width: 199px !important;
  padding: 10px 24px !important;
  flex-direction: column;
  align-items: center !important;
  gap: 15px !important;
  background-color: #fff1c6 !important;
  border-radius: 10px !important;
}

.create-workspace-text {
  color: #79310e !important;
  text-align: center !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal !important;
  align-self: stretch;
}

.create-workspace-button {
  display: flex;
  width: 128px !important;
  height: 40px !important;
  padding: 12px 17px !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  background-color: #b64c07 !important;
  border-radius: 4px !important;
  border: 3px solid rgba(255, 255, 255, 0.45) !important;
}

.create-workspace-button-text {
  color: #fef8ec !important;
  font-family: Kanit !important;
  font-size: 12.959px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.profile-card-image {
  gap: 10px !important;
  display: flex;
  padding: 19px 20px !important;
  justify-content: center !important;
  align-items: center !important;
  align-self: stretch;
}

.profile-card-profile-avatar {
  width: 32px !important;
  height: 32px !important;
  border-radius: 32px !important;
  background: url('https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png')
    lightgray 50% / cover no-repeat;
}

.profile-card-email {
  color: #79310e !important;
  font-family: Kanit !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.profile-card-name {
  color: #79310e !important;
  font-family: Kanit !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.profile-card-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.profile-card {
  display: flex;
  align-items: center;
  gap: 7px;
}

/* workspace title styles */

.workspace-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  width: 425px !important;
}

.workspace-upgrade-button-text {
  color: #fff !important;
  font-family: Roboto !important;
  font-size: 13.867px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 17.333px !important; /* 125% */
}

.workspace-upgrade-button {
  display: flex;
  padding: 7px 20px;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
  border-radius: 5.2px !important;
  border: 2px solid rgba(255, 255, 255, 0.45) !important;
  background: #b64c07 !important;
}

.workspace-heading {
  display: flex;
  align-items: center;
  gap: 544px;
  width: 100%;
  align-self: flex-start;
  overflow: hidden;
  justify-content: space-between;
}

.workspace-heading-notification-icon-section {
  display: flex;
  align-items: center;
  gap: 40px;
}

.workspace-notification {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 3px 4px;
  flex-direction: column;
  align-items: center;
}

.notification-icon-bell {
  width: 6px;
  height: 4px;
  flex-shrink: 0;
  stroke-width: 1px;
  stroke: #79310e !important;
}

.notification-icon-bell-upper {
  width: 16px;
  height: 14px;
  flex-shrink: 0;
  stroke-width: 1px;
  stroke: #79310e !important;
}

.landing-page-button-extension {
  display: flex;
  padding: 9.5px 16.5px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px;
  border-radius: 112.5px !important;
  border: 1.731px solid rgba(0, 0, 0, 0.25) !important;
  background: #ffe288 !important;
}

.landing-page-button-extension-text {
  color: #66442c !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px !important; /* 150% */
  letter-spacing: -0.16px;
}

.landing-page-sign-in-button {
  display: flex;
  width: 314px !important;
  height: 46px !important;
  padding: 19px 80px 19px 24px;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  border-radius: 4px !important;
  border: 3px solid rgba(255, 255, 255, 0.45) !important;
  background: #b64c07 !important;
}

.landing-page-sign-in-button-text {
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 20px; /* 142.857% */
}

.settings-general-cancel-button {
  display: flex;
  width: 71px !important;
  padding: 8px 16px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px !important;
  border: 2px solid #fff1c6 !important;
  background: #fffaeb !important;
}

.settings-general-cancel-button-text {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 12.959px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.settings-general-save-button {
  display: flex;
  width: 71px !important;
  height: 32px !important;
  padding: 12px 17px;
  justify-content: center;
  align-items: center;
  gap: 10px !important;
  border-radius: 4px !important;
  border: 3px solid rgba(255, 255, 255, 0.45) !important;
  background: #b64c07 !important;
}

.settings-general-save-button-text {
  color: #fef8ec !important;
  font-family: Lato !important;
  font-size: 12.959px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.MuiTabs-indicator {
  background-color: #79310e !important;
}

.tab-selected .MuiTypography-root {
  color: #79310e !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0.07px;
}

.MuiTabs-flexContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.MuiFormControlLabel-root {
  display: flex;
  flex-direction: row;
  gap: 26px;
  justify-items: center;
  align-items: flex-start;
}

.MuiFormGroup-root {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.settings-general-email-notifications-option {
  color: #222 !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 22px; /* 157.143% */
  align-self: stretch;
}

.settings-general-email-notifications-option-description {
  color: #222 !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 22px; /* 157.143% */
  align-self: stretch;
}

.settings-general-workspace-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.settings-general-email-input {
  display: flex;
  height: 32px !important;
  padding: 8px 16px !important;
  align-items: center !important;
  gap: 10px !important;
  align-self: stretch;
  border-radius: 6px !important;
  border: 1px solid #ffdd96 !important;
  background: #fff1c6 !important;
}

.MuiOutlinedInput-root.Mui-disabled {
  background-color: #fff1c6 !important;
}

.dds-input {
  border: 1px solid #ffdd96 !important;
  height: auto !important;
}

.input-mui-text-color-disabled .MuiInputBase-input.Mui-disabled {
  color: #626262 !important;
  -webkit-text-fill-color: #626262 !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 22px; /* 157.143% */
  flex: 1 0 0 !important;
}

.settings-general-email-notifications {
  color: #222 !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 22px; /* 157.143% */
  align-self: stretch;
}

.settings-general-workspace-heading,
.settings-general-email-section {
  color: #222 !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 22px; /* 157.143% */
}

.assist-icon-section {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 45px;
}

@media (max-width: 1430px) {
  .assist-icon-section {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.empty-icon-description {
  width: 207px;
  color: #a26547 !important;
  text-align: center !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal !important;
}

/* plan css */

.plan-container {
  border: 1px solid #febc2e;
  border-radius: 12px;
  padding-top: 20px;
  flex-shrink: 0;
  width: 1037px;
}

.plan-header {
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-right: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #febc2e;
}

.plan-body {
  display: flex;
  justify-content: space-between;
}

.plan-streams-divider {
  stroke-width: 1px;
  stroke: #febc2e !important;
}

.current-plan-text {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.07px !important;
  width: 79px !important;
}

.current-plan-scheme-text {
  color: #222 !important;
  font-family: Lato !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px !important; /* 83.333% */
  letter-spacing: 0.12px !important;
  align-self: stretch;
}

.current-plan-cost-text {
  padding-top: 20px;
  color: #222 !important;
  font-family: Lato !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 50px !important;
}

.plan-streams-section,
.plan-workspace-section {
  flex: 1 1;
  padding-left: 30px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-end;
  padding-bottom: 13px;
}

.stream-count,
.count-personal-workspace {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px !important; /* 83.333% */
  letter-spacing: 0.12px !important;
  align-self: stretch;
}

.stream-note,
.personal-workspace-note {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.07px !important;
}

.plan-heading {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 22px !important; /* 91.667% */
  align-self: stretch;
}

.plan-description {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 22px !important; /* 157.143% */
  align-self: stretch;
}

.plan-cost {
  color: #000 !important;
  font-family: Lato !important;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 22px !important; /* 68.75% */
  align-self: stretch;
}

.plan-features-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important; /* 137.5% */
  align-self: stretch;
}

.plan-features-list {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 400 !important;
  line-height: 22px !important; /* 157.143% */
}

.plan-features-list-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.upgrade-button {
  display: flex;
  padding: 10px 20px;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
  align-self: stretch;
  border-radius: 5.2px !important;
  border: 2px solid rgba(255, 255, 255, 0.45) !important;
  background: #b64c07 !important;
}

.upgrade-button-text {
  color: #fff !important;
  font-family: Roboto !important;
  font-size: 13.867px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 17.333px !important; /* 125% */
}

.all-plans-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 22px !important; /* 137.5% */
}

.rc-select-single .rc-select-selector .rc-select-selection-item {
  color: #79310e !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 22px !important;
  width: 141px !important;
}

.dds-select-component
  .rc-select-single:not(.rc-select-customize-input)
  .rc-select-selector {
  display: flex;
  height: 32px;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px !important;
  background: #fff !important;
}

.override-select-border
  .dds-select-component
  .rc-select-single:not(.rc-select-customize-input)
  .rc-select-selector {
  border: 1px solid #febc2e !important;
}

.rc-select-single .rc-select-selector .rc-select-selection-item {
  color: #79310e !important;
}

.rc-select-item-option-content {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  text-align: left !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: -0.15px !important;
  align-self: stretch;
}

.cost-description-workspace {
  color: #000 !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 22px !important;
}

.limit-reached-title {
  color: #79310e !important;
  text-align: center !important;
  font-family: Lato !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 25px !important; /* 192.308% */
}

.limit-reached-description {
  color: #79310e !important;
  text-align: center !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 25px !important; /* 192.308% */
}

.limit-reached-upgrade-button {
  display: flex;
  padding: 5px 10px !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
  border-radius: 4px !important;
  border: 3px solid rgba(255, 255, 255, 0.45) !important;
  background: #b64c07 !important;
}

.limit-reached-upgrade-button-text {
  color: #fff !important;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 20px !important; /* 142.857% */
}

.profile-card-menu .dds-mui-menu-wrapper {
  display: flex;
  border: 1px solid #febc2e !important;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  gap: 10px !important;
  flex-shrink: 0;

  background: #fff !important;
}

.dds-list-menu {
  padding: 1px 0px !important;
}

.profile-menu-item-logout-text {
  color: #79310e;

  font-family: Lato !important;
  /* font-size: 10px !important; */
  font-style: normal;
  font-weight: 400 !important;
  line-height: 16.615px !important; /* 166.154% */
  letter-spacing: 0.05px !important;
}

.menu-profile .dds-mui-menu-wrapper {
  display: flex;
  width: 155px;
  height: 38px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-bottom: 0.5px solid var(--Selective-Yellow-400, #febc2e);
  background: #fff;
}

.profile-menu-item-settings-text {
  color: #79310e;
  font-family: Lato !important;
  /* font-size: 10px !important; */
  font-style: normal;
  font-weight: 400 !important;
  line-height: 16.615px !important; /* 166.154% */
  letter-spacing: 0.05px !important;
}

.cancel-plan-text {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal;
  cursor: pointer;
  font-weight: 600 !important;
  line-height: normal;
  text-decoration-line: underline;
}

.modal-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 22px !important; /* 157.143% */
}

.modal-note {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 22px !important; /* 157.143% */
}

.modal-proceed-button {
  display: flex;
  height: 40px !important;
  padding: 12px 17px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px !important;
  border: 3px solid rgba(255, 255, 255, 0.45) !important;
  background: #b64c07 !important;
}

.modal-proceed-button-text {
  color: #fef8ec !important;
  font-family: Kanit !important;
  font-size: 12.959px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal !important;
}

.modal-content .modal-content-padding {
  padding: 20px;
  padding-top: 40px;
}

.status-chip {
  display: flex;
  height: 27.733px !important;
  min-width: 55.467px !important;
  min-height: 27.733px !important;
  max-height: 32.933px !important;
  padding: 0px 1px !important;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px !important;
}

.antd-table-container-shape-roundedRectangle,
.mui-table-container-shape-roundedRectangle {
  border-radius: 12px !important;
}

.antd-table-container-shape-roundedRectangle,
.mui-table-container-shape-roundedRectangle {
  border-radius: 12px !important;
}

.dds-mui-table-head {
  background-color: #fff !important;
}

.new-workspace-modal-padding .modal-content-padding {
  padding: 20px !important;
}

.new-workspace-modal-title {
  color: #222 !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 22px !important; /* 157.143% */
}

.create-workspace-button {
  display: flex;
  height: 40px !important;
  width: 100% !important;
  padding: 12px 17px !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  align-self: stretch;
  border-radius: 4px !important;
  border: 3px solid rgba(255, 255, 255, 0.45) !important;
  background: #b64c07 !important;
}

.create-workspace-button-text {
  color: #fef8ec !important;
  font-family: Kanit !important;
  font-size: 12.959px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.modal-min-width .mui-modal-overlay-box {
  min-width: 400px !important;
}

.modal-width .dds-modal-shape-rounded-rectangle {
  width: 412px !important;
}

.invite-modal-padding .modal-content-padding {
  padding: 15px !important;
}

.send-invite-button-text {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 12.959px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.send-invite-button {
  display: flex;
  width: 81px !important;
  padding: 8px 16px !important;
  justify-content: center;
  align-items: center !important;
  gap: 10px !important;
  border-radius: 5px !important;
  border: 2px solid #ffe288 !important;
  background: #fff;
}

.invite-member-input {
  display: flex;
  padding: 8px 12px !important;
  align-items: center !important;
  gap: 10px !important;
  flex: 1 0 0 !important;
  border-radius: 8px;
  border: 1px solid, #febc2e !important;
}

.input-invite-email .dds-input-field-wrapper {
  padding: 0px !important;
}

.invite-member-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 25px !important; /* 125% */
}

.invite-member-description {
  color: #737373 !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.invite-member-placeholder .MuiOutlinedInput-input {
  color: #f2b189 !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important; /* 171.429% */
  letter-spacing: -0.14px !important;
}

.invite-member-instructions {
  color: #737373 !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.copy-link-input {
  color: #b64c07 !important;
  font-family: Lato !important;
  font-size: 14px !important;
  cursor: pointer;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important; /* 171.429% */
  letter-spacing: -0.14px !important;
}

.new-member-invite-name {
  color: #000 !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important; /* 171.429% */
}

.new-member-email {
  color: #000 !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important; /* 200% */
}

.invited-chip-text {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.invited-member .MuiChip-label {
  color: #79310e !important;
}

.members-limit-reached-modal-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 25px !important; /* 125% */
  display: flex;
  justify-content: flex-start;
}

.exceeded-members-description {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 22px !important; /* 157.143% */
}

.see-plans-button {
  display: flex;
  height: 40px !important;
  padding: 12px 17px !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  align-self: stretch;
  border-radius: 4px !important;
  border: 3px solid rgba(255, 255, 255, 0.45) !important;
  background: #b64c07 !important;
}

.see-plans-button-text {
  color: #fef8ec !important;
  font-family: Kanit !important;
  font-size: 12.959px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.limit-exceeded-members-modal-layout .mui-modal-overlay-box {
  display: flex;
  width: 412px !important;
  min-width: none;
  flex-direction: column;
  align-items: flex-start !important;
  flex-shrink: 0;
}

.invite-members-modal .mui-modal-overlay-box {
  top: 400px !important;
}

.selected-member .dds-mui-table-row-bg {
  background-color: #fffaeb !important;
}

.table-members-header {
  display: flex;
  height: 48px;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.members-table-container {
  font-family: Arial, sans-serif;
}

.members-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.invite-button {
  background-color: #c2410c;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.members-table {
  width: 900px;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #fff1c6;
  border-radius: 8px;
  overflow: hidden;
}

.members-table th,
.members-table td {
  padding: 12px;
  text-align: left;
}

.members-table th {
  background-color: #fff9e6;
  font-weight: normal;
  color: #666;
}

.members-table :hover {
  background-color: #fffaeb !important;
}

.members-table td {
  background-color: #fffcf2;
}

.members-table tr:not(:last-child) td {
  border-bottom: 1px solid #fff1c6;
}

.checkbox-cell {
  width: 20px;
}

.role-cell {
  color: #999;
  font-size: 0.9em;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
}

.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.name-cell {
  display: flex;
  align-items: center;
}

.member-name,
.member-email {
  color: #222 !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px !important; /* 114.286% */
  letter-spacing: 0.07px !important;
}

.member-role {
  color: #999 !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px !important; /* 114.286% */
  letter-spacing: 0.07px !important;
}

.members-count {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.selected-organization-workspace {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal !important;
}

.selected-organization-menu :hover {
  cursor: pointer;
  border-radius: 5px !important;
  /* border: 0.5px solid #fffaeb !important; */
}

.selected-organization-menu {
  background-color: #fff1c6;
  height: 46px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding: 14px 16px;
  flex-direction: row;
}

.sidebar-streams-title,
sidebar-settings-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.streams-outer-layout {
  display: flex;
  padding: 0px 16px !important;
  flex-direction: column !important;
  align-items: flex-start;
  gap: 12px !important;
  align-self: stretch;
}

.streams-inner-layout {
  display: flex;
  width: 191px !important;
  height: 28px !important;
  padding: 5.5px 16px !important;
  align-items: center;
  gap: 10px !important;
}

.streams-inner-layout :hover {
  cursor: pointer;
}

.my-workspaces-title,
.joined-workspaces-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.workspace-menu:hover {
  cursor: pointer;
}

.dropdown-workspaces .dds-menu-rounded-rectangle {
  border-radius: 0px !important;
  box-shadow: none !important;
  width: 225px !important;
}

.workspace-name {
  color: #000;
  font-family: Lato !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.dropdown-create-workspace-section {
  display: flex;
  height: 40px !important;
  margin-top: 15px;
  padding: 10px 79px 10px 10px !important;
  align-items: center !important;
  border-radius: 0px 0px 6px 6px !important;
  border-top: 1px solid #fff1c6 !important;
  background: #fffaeb !important;
}

.dropdown-create-workspace-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 12.959px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  width: 100%;
}

.dropdown-create-workspace-title:hover {
  cursor: pointer;
}

.dropdown-list:hover {
  color: #f89608 !important;
}

.invite-member-button {
  display: flex;
  width: 128px;
  height: 40px;
  padding: 12px 17px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px !important;
  border: 3px solid rgba(255, 255, 255, 0.45) !important;
  background: #b64c07 !important;
}

.invite-member-button-text {
  color: #fef8ec !important;
  font-family: Kanit !important;
  font-size: 12.959px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.input-member-disabled .MuiOutlinedInput-root.Mui-disabled {
  background-color: #fff !important;
}

.dds-input-field-disabled {
  background-color: #fff1c6 !important;
}

.downgraded-page-logo-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 23.786px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 33.036px !important; /* 138.889% */
}

.downgraded-page-description {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important; /* 160% */
  width: 354px !important;
  align-items: justify !important;
}

.downgraded-page-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 42px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 54px !important; /* 128.571% */
  width: 368px !important;
}

.downgraded-upgrade-button {
  display: flex;
  width: 229.075px !important;
  height: 49px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12.25px !important;
  flex-shrink: 0 !important;
  border-radius: 4.9px !important;
  border: 3.675px solid rgba(255, 255, 255, 0.45) !important;
  background: #b64c07 !important;
}

.downgraded-upgrade-button-text {
  color: var(--Zest-50, #fef8ec) !important;
  font-family: Kanit !important;
  font-size: 19.6px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.video-card {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
}

.video-thumbnail {
  position: relative;
  height: 150px; /* Adjust as needed */
  width: 260px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-icon {
  position: absolute;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.video-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  text-align: center;
  padding: 10px;
  transform: translateY(100%);
  justify-content: space-around;
  gap: 75px;
  transition: transform 0.3s ease;
}

.video-card:hover .video-info {
  transform: translateY(0);
  padding: 10px;
  display: flex;
  gap: 70px;
  justify-content: space-around;
  align-items: center;
}

.video-updated-text {
  color: white !important;
  font-family: Lato !important;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.video-total-comments {
  color: white !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.delete-workspace-button {
  display: flex;
  width: 132px !important;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px !important;
  border-radius: 5px;
  border: 2px solid #fff1c6 !important;
  background: #fffaeb !important;
}

.delete-workspace-button-text {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 12.959px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.delete-workspace-title {
  color: #222 !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 22px !important; /* 157.143% */
}

.delete-workspace-modal-padding .modal-content-padding {
  padding: 20px !important;
}

.delete-workspace-modal-button {
  display: flex;
  height: 40px !important;
  padding: 12px 17px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px !important;
  align-self: stretch;
  border-radius: 4px !important;
  border: 3px solid rgba(255, 255, 255, 0.45) !important;
  background: #b64c07 !important;
}

.delete-workspace-modal-button-text {
  color: #fef8ec !important;
  font-family: Kanit !important;
  font-size: 12.959px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.delete-workspace-modal-description {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 22px !important; /* 157.143% */
  align-self: stretch;
}

.delete-workspace-modal-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 25px !important;
}

.delete-workspace-modal {
  display: flex;
  width: 412px !important;
  flex-direction: column;
  align-items: flex-start !important;
  gap: 10px !important;
  flex-shrink: 0;
}

.delete-checkbox .MuiFormControlLabel-root {
  margin-left: 0px;
  margin-right: 0px;
  position: absolute;
}
.dds-loader {
  height: 100%;
  justify-content: center;
}
.rotating-loader {
  color: #b64c07 !important;
}

.tooltip-content .MuiTooltip-tooltip {
  display: flex;
  width: 261px !important;
  padding: 8px 10px !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 4px !important;
  flex-shrink: 0 !important;
  border-radius: 6px !important;
}

.tooltip-description {
  color: #fff !important;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  letter-spacing: 0.06px;
}

.selected-organization-menu-hover :hover {
  border-radius: 5px !important;
  border: 1px solid #fffaeb !important;
}

.comment-stream-title-unauthorized-page {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 23.786px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 33.036px !important;
}

.login-title-unauthorized-page {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.add-ons-padding .modal-content-padding {
  padding: 20px !important;
}

.add-ons-modal-summary-title {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  align-self: stretch;
}

.add-ons-cost-per-seat {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.add-ons-cost-per-seat-description {
  color: #737373 !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.add-ons-members-count {
  color: #737373 !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.add-ons-total-cost {
  color: #79310e !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}
